import { Sprite, SpriteSystemSVG } from '@sbercloud/uikit-product-icons';
import { Layout as UIKitLayout } from '@sbercloud/uikit-product-site-layout';
import { ReactNode } from 'react';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useGtmAddRoute } from 'src/hooks/useGtmAddRoute';
import { useStoreState } from 'src/store/index';
import { CustomTheme } from 'src/types';

import { CookiePop } from './CookiePop';
import { Footer } from './Footer';
import { Header } from './Header';
import { UnsupportedTechMessages } from './UnsupportedTechMessages';

function LoadingSpinnerMain() {
  return (
    <div style={{ minHeight: 700, marginTop: 100 }}>
      <LoadingSpinner />
    </div>
  );
}

interface LayoutProps {
  customTheme?: CustomTheme;
  showHeader?: boolean;
  showFooter?: boolean;
  children: ReactNode;
  withoutSearch?: boolean;
  withoutHeaderShadow?: boolean;
  withoutFooterSubscribe?: boolean;
  withConsultationButton?: boolean;
  staticHeader?: boolean;
}

/**
 * Layout
 *
 */
export function Layout({
  staticHeader = false,
  showHeader = true,
  showFooter = true,
  children,
  withoutSearch,
  withoutHeaderShadow,
  withoutFooterSubscribe,
  withConsultationButton = true,
}: LayoutProps) {
  const state = useStoreState();
  useGtmAddRoute();

  return (
    <UIKitLayout
      meta={
        <>
          <Sprite content={SpriteSystemSVG} />
        </>
      }
    >
      <UnsupportedTechMessages />
      {showHeader && (
        <Header
          showLayoutBanner
          withoutSearch={withoutSearch}
          withoutHeaderShadow={withoutHeaderShadow}
          staticHeader={staticHeader}
          withConsultationButton={withConsultationButton}
        />
      )}
      <UIKitLayout.Main>
        {state?.loadingStatus === 'success' ? children : <LoadingSpinnerMain />}
      </UIKitLayout.Main>
      {showFooter && <Footer disableSubscribe={withoutFooterSubscribe} />}
      <CookiePop />
      <div id="portal" />
    </UIKitLayout>
  );
}
