import Cookies from 'js-cookie';
import { AuthContextProps } from 'react-oidc-context';
import { COOKIE_DOMAIN } from 'src/global';

const OIDC_DOMAIN_COOKIE_SESSION = 'oidc-domain-session-active';
const INTERVAL_BETWEEN_CHECKS = 5 * 1000; // 5 seconds

const OIDC_COOKIE_CONFIG = {
  path: '/',
  domain: `.${COOKIE_DOMAIN}`,
};

export const getOidcCookie = () => Cookies.get(OIDC_DOMAIN_COOKIE_SESSION);

export const validateSession = (
  signoutRedirect: AuthContextProps['signoutRedirect'],
) => {
  const oidcCookie = getOidcCookie();
  if (Boolean(oidcCookie)) {
    return;
  }

  signoutRedirect();
};

export const startSessionValidation = (
  signoutRedirect: AuthContextProps['signoutRedirect'],
) =>
  setInterval(() => validateSession(signoutRedirect), INTERVAL_BETWEEN_CHECKS);

export const terminateSession = (
  signoutRedirect: AuthContextProps['signoutRedirect'],
) => {
  Cookies.remove(OIDC_DOMAIN_COOKIE_SESSION, OIDC_COOKIE_CONFIG);
  signoutRedirect();
};
