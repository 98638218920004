import cn from 'classnames';
import { Habr, Telegram, Vkontakte, Youtube } from 'src/components/Icons';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { SocialNetworkShortName } from 'src/types';

import s from './SocialButtons.module.scss';

interface SocialButtonProps {
  classNameImg?: string;
  classNameImgHb?: string;
  classNameImgTg?: string;
  classNameImgVk?: string;
  classNameImgYt?: string;
  classNameSocialButton?: string;
  name: SocialNetworkShortName;
}

function SocialButton({
  classNameImg,
  classNameImgHb,
  classNameImgTg,
  classNameImgVk,
  classNameImgYt,
  classNameSocialButton,
  name,
}: SocialButtonProps) {
  const { clickAnalytics } = useAnalytics();

  let icon = null;
  let link = '';

  switch (name) {
    case 'vk':
      icon = (
        <Vkontakte
          className={cn(s.img, s.imgVk, classNameImg, classNameImgVk)}
        />
      );
      link = 'https://vk.com/cloudruprovider';
      break;
    case 'tg':
      icon = (
        <Telegram
          className={cn(s.img, s.imgTg, classNameImg, classNameImgTg)}
        />
      );
      link = 'https://t.me/cloudruprovider';
      break;
    case 'yt':
      icon = (
        <Youtube className={cn(s.img, s.imgYt, classNameImg, classNameImgYt)} />
      );
      link = 'https://www.youtube.com/@cloudru_team';
      break;
    case 'hb':
      icon = (
        <Habr className={cn(s.img, s.imgHb, classNameImg, classNameImgHb)} />
      );
      link = 'https://habr.com/ru/companies/cloud_ru/articles/';
      break;
    default:
      break;
  }

  return (
    <a
      aria-label="Social button"
      className={cn(s.socialButton, classNameSocialButton)}
      href={link}
      target="_blank"
      onClick={() => {
        clickAnalytics({
          action: '/',
          clickZone: 'footer',
          clickElement: 'cardclick',
          clickContent: link,
          uniqueId: `footer-social-${link}`,
          transitionType: 'inside-link',
        });
      }}
      rel="noreferrer"
      data-qa="social-button"
    >
      {icon}
    </a>
  );
}

/**
 * SocialButtons
 */

interface SocialButtonsProps {
  socialNamesArray?: SocialNetworkShortName[];
  classNameImg?: string;
  classNameImgHb?: string;
  classNameImgTg?: string;
  classNameImgVk?: string;
  classNameImgYt?: string;
  classNameSocialButton?: string;
}

export function SocialButtons({
  socialNamesArray = ['hb', 'tg', 'yt', 'vk'],
  className,
  classNameImg,
  classNameImgHb,
  classNameImgTg,
  classNameImgVk,
  classNameImgYt,
  classNameSocialButton,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & SocialButtonsProps) {
  return (
    <div className={cn(s.root, className)} {...rest}>
      {socialNamesArray.map((name, index) => (
        <SocialButton
          key={index}
          name={name}
          classNameImg={classNameImg}
          classNameImgHb={classNameImgHb}
          classNameImgTg={classNameImgTg}
          classNameImgVk={classNameImgVk}
          classNameImgYt={classNameImgYt}
          classNameSocialButton={classNameSocialButton}
        />
      ))}
    </div>
  );
}
