import {
  ButtonPromo,
  ButtonPromoOutline,
} from '@sbercloud/uikit-product-button-predefined';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './PlatformCard.module.scss';

interface PlatformCardProps {
  title: string;
  link: string;
  linkText: string;
  clickzone: 'search' | 'body' | 'footer' | 'header' | 'catalog-search';
  text?: string;
  theme?: string;
  className?: string;
}

export function PlatformCard({
  title,
  text,
  link,
  linkText,
  theme,
  clickzone,
  className = '',
}: PlatformCardProps) {
  const { clickAnalytics } = useAnalytics();

  return (
    <div
      className={cn(s.root, className)}
      style={theme ? { backgroundColor: theme } : {}}
    >
      <div
        className={s.description}
        style={theme ? { backgroundColor: theme } : {}}
      >
        <div>
          <Typography
            className={s.title}
            tag="div"
            size="l"
            family="sans"
            purpose="label"
          >
            {title}
          </Typography>
        </div>
        {text && (
          <div>
            <Typography
              tag="div"
              size="m"
              family="sans"
              purpose="body"
              className={s.text}
            >
              {text}
            </Typography>
          </div>
        )}
      </div>

      <ButtonPromoOutline
        label={linkText}
        appearance="secondary"
        size="s"
        href={link}
        onClick={() => {
          clickAnalytics({
            action: link,
            clickZone: clickzone,
            clickElement: 'textclick',
            clickContent: title,
            uniqueId: `platform-card-link-${title}`,
            transitionType: 'inside-link',
          });
        }}
      />
    </div>
  );
}
