import { useEffect, useState } from 'react';
import { useSearchParams } from 'src/contexts/SearchParams/useSearchParams';
import { searchAnalyticsType, useAnalytics } from 'src/hooks/useAnalytics';
import { useDebounce } from 'src/hooks/useDebounce';
import { useGetUserMetaData } from 'src/hooks/useGetUserMetaData';
import { CatalogProduct, ResponseV4Search } from 'src/types/backendContent';
import { fetchElasticSearch } from 'src/utils/fetchElasticSearch';

type UseElasticSearchReturn = {
  /** Найденные и объединенные продукты */
  searched: CatalogProduct[];
  /** Состояние загрузки */
  loading: boolean;
  debouncedPhrase: string;
};

type UseElasticSearchType = {
  phrase: string;
  products: CatalogProduct[];
  filteredProducts: CatalogProduct[];
  platform?: string;
  typeAnalytics: searchAnalyticsType;
};

export const useElasticProductsSearch = ({
  phrase,
  products,
  filteredProducts,
  platform,
  typeAnalytics,
}: UseElasticSearchType): UseElasticSearchReturn => {
  const { searchAnalytics } = useAnalytics();
  const userMetaData = useGetUserMetaData();
  const searchParams = useSearchParams();
  const debouncedPhrase = useDebounce(phrase.trim(), 400);
  const [searched, setSearched] = useState<CatalogProduct[]>([]);
  const [loading, setLoading] = useState(false);

  const controller = new AbortController();
  const signal = controller.signal;

  const searchElastic = async (): Promise<CatalogProduct[]> => {
    if (phrase.trim().length < 2) {
      setLoading(false);
      return [];
    }

    const shadowParam = searchParams.get('shadow');

    const isShadow = shadowParam !== undefined && shadowParam === 'string';

    const shadow = isShadow ? shadowParam : '';

    searchAnalytics(typeAnalytics, phrase);

    const timer = setTimeout(() => controller.abort(), 3000);
    try {
      const body = JSON.stringify({
        commonParams: {
          phrase: [debouncedPhrase],
          ow: ['true'],
          use_ranking: ['true'],
        },
        nocommit: !userMetaData.gaClientID ? true : false,
        requestContext: {
          phrase: debouncedPhrase,
          gaClientID: userMetaData.gaClientID,
          originalUTM: userMetaData.originalUTM,
          retainURL: userMetaData.retainURL,
          yaClientID: userMetaData.yaClientID,
          shadowInput: shadow,
        },
        sources: [
          {
            names: ['site', 'solutions'],
          },
        ],
      });

      const res = await fetchElasticSearch({ body, signal })
        .then((res) => {
          if (!res.ok) {
            throw Error(res.statusText);
          }
          return res.json();
        })
        .then((res: ResponseV4Search) => {
          const products = res.data?.categories?.find((item) =>
            ['site', 'solutions'].find((source) => item.slug.includes(source)),
          );

          return products?.data || [];
        })
        .then((res) => {
          return res
            .map((productV4) => {
              return products.find((product) => {
                return (
                  product.slug === productV4.slug.split('/').at(-1) &&
                  (!platform ||
                    product.productPlatforms.find(
                      (productPlatform) => productPlatform.slug === platform,
                    ))
                );
              });
            })
            .filter((product) => product !== undefined);
        })
        .catch((e) => {
          throw Error(e.message);
        })
        .finally(() => {
          setLoading(false);
          clearTimeout(timer);
        });

      return res || [];
    } catch {
      clearTimeout(timer);
      return [];
    }
  };

  /**
   * FIXME: При клиентском роутинге из шапки отрабатывает некорректно
   * возможно причина в неполном массиве зависимостей хука
   */
  useEffect(() => {
    if (phrase.length < 1) {
      setSearched(productsCombination(filteredProducts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProducts]);

  useEffect(() => {
    setLoading(true);
    searchElastic()
      .then((elastic) =>
        setSearched(productsCombination(filteredProducts, elastic)),
      )
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPhrase]);

  return { searched, loading, debouncedPhrase };
};

export const productsCombination = (
  nativeSearched: CatalogProduct[],
  elasticSearched?: CatalogProduct[],
): CatalogProduct[] => {
  const union = nativeSearched.map((native) => {
    let indexOffoundedInElastic = -1;
    const founded = elasticSearched?.find((elastic, index) => {
      if (elastic.slug === native.slug) {
        indexOffoundedInElastic = index;
        return true;
      }
      return false;
    });

    if (indexOffoundedInElastic !== -1)
      elasticSearched?.splice(indexOffoundedInElastic, 1);

    const analyticSource: CatalogProduct['analyticSource'] = founded
      ? 'all'
      : 'filter';

    native.analyticSource = analyticSource;
    return native;
  });

  elasticSearched?.forEach((elastic) => {
    elastic.analyticSource = 'e-search';
    union.push(elastic);
  });

  return union;
};
