import { ButtonFilled } from '@snack-uikit/button';
import { Typography } from '@snack-uikit/typography';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { DESKTOP_MEDIA_QUERY } from '.';
import s from './Footer.module.scss';

export function FooterSubscribe({ disable = false }: { disable?: boolean }) {
  const { clickAnalytics } = useAnalytics();
  const { windowWidth } = useWindowWidth();

  return (
    <div className={s.footerSubscribe}>
      <div className={s.footerSubscribeDescription}>
        <Typography
          tag="div"
          size="l"
          family="sans"
          purpose="title"
          className={s.footerSubscribeTitle}
        >
          Будьте в курсе
        </Typography>

        <Typography
          tag="p"
          size="m"
          family="sans"
          purpose="body"
          className={s.footerSubscribeText}
        >
          Анонсируем мероприятия, делимся кейсами клиентов и полезными
          материалами.
        </Typography>
      </div>
      <ButtonFilled
        label="Подписаться на рассылку"
        href="/subscription"
        className={s.button}
        fullWidth={
          windowWidth && windowWidth < DESKTOP_MEDIA_QUERY ? true : false
        }
        size={windowWidth && windowWidth < DESKTOP_MEDIA_QUERY ? 'l' : 'm'}
        target="_self"
        disabled={disable}
        onClick={() =>
          clickAnalytics({
            action: '/subscription',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: 'Подписаться на рассылку',
            uniqueId: `footer-subscription-button`,
            transitionType: 'inside-link',
          })
        }
      />
    </div>
  );
}
