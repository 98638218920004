import { InternetSVG } from '@sbercloud/uikit-product-icons';
import { ButtonFunction } from '@snack-uikit/button';
import { Droplist } from '@snack-uikit/list';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useAsPath } from 'src/hooks/useAsPath';

const LANG_OPTIONS = [
  { id: 'ru', content: 'Русский', href: '/' },
  {
    id: 'cn',
    content: '中文',
    href: 'https://cloudru.cn/',
  },
];

export function LocaleSwitcher() {
  const { push } = useRouter();
  const asPath = useAsPath();

  const [ruLang, cnLang] = LANG_OPTIONS;

  const currentLang = asPath === cnLang.href ? cnLang : ruLang;

  const [lang, setLang] = useState<string>(currentLang.id);

  const { clickAnalytics } = useAnalytics();

  const handleLangChange = (value: string) => {
    if (value === lang || !value) {
      return;
    }

    clickAnalytics({
      action: 'https://cloudru.cn',
      clickZone: 'footer',
      clickElement: 'buttonclick',
      clickContent: 'localeSwitcher',
      uniqueId: `footer-localeSwitcher`,
      transitionType: 'outside-link',
    });

    const selectedLang = LANG_OPTIONS.find(({ id }) => id === value);

    setLang(value);

    if (selectedLang && selectedLang.id === value && push) {
      push(selectedLang.href);
    }
  };

  return (
    <Droplist
      trigger="click"
      items={LANG_OPTIONS}
      placement="bottom"
      closeDroplistOnItemClick
      selection={{ value: lang, onChange: handleLangChange, mode: 'single' }}
    >
      <ButtonFunction
        icon={<InternetSVG size={24} />}
        iconPosition="before"
        label={currentLang.content}
      />
    </Droplist>
  );
}
