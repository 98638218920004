import { ArrowLeftSVG } from '@sbercloud/uikit-product-icons';
import { MobileModalCustom } from '@sbercloud/uikit-product-mobile-modal';
import { ButtonFunction } from '@snack-uikit/button';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { PortalModal } from 'src/layout/PortalModal';
import { Button } from 'src/uikit/Button';
import { Variant } from 'src/uikit/Button/constants';
import { ChevronLeftIcon } from 'src/uikit/icons/ChevronLeftIcon';

import {
  ContentType,
  InfoType,
  ListGridType,
  ListType,
  ProductsType,
} from '../../types';
import { BurgerButton } from '../BurgerButton';
import { List } from '../List';
import { Products } from '../Products';
import s from './ModalMenu.module.scss';

interface ModalMenuProps {
  className?: string;
  content: {
    id?: string;
    infoBlock?: InfoType;
    type: ContentType;
    links?: { title: string; text: string; link: string }[][];
    catalogItems?: ProductsType;
    list?: ListType;
    gridColumns?: ListGridType;
    title?: string;
  };

  onClickForClose(): void;
  onClickOnSameLink(): void;
  showSubheader?: boolean;
  onBurgerClickHandler: () => void;
  isBurgerOpen: boolean;
  headerHeight: number;
}

export function ModalMenu({
  className = '',
  content,
  onClickForClose,
  onClickOnSameLink,
  showSubheader = false,
  isBurgerOpen,
  onBurgerClickHandler,
  headerHeight,
}: ModalMenuProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  const { clickAnalytics } = useAnalytics();
  const wrapperInfoClassName = content.list;
  const HEADER_DESKTOP_WIDTH = 1269;

  return windowWidth && windowWidth > HEADER_DESKTOP_WIDTH ? (
    <PortalModal
      portalClassName={cn(s.modal, s.layout)}
      overlayClassName={s.overlay}
      onClickForClose={onClickForClose}
      withoutDefaultCloseIcon
    >
      <div
        className={cn(s.root, className, {
          [s.withoutSubheader]: !showSubheader,
        })}
        style={windowWidth! > MD ? { top: `${headerHeight - 1}px` } : {}}
      >
        <div
          className={cn(s.wrapper, {
            [s.info]: !!content.infoBlock,
            [s.manyLinks]: content.type === 'catalog',
            [s.staticHeight]: wrapperInfoClassName,
          })}
        >
          {windowWidth && windowWidth <= MD && (
            <div className={s.modalHeader}>
              <Button
                className={s.back}
                onClick={onClickForClose}
                variant={Variant.Tertiary}
                data-qa="menu-back-button"
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'search',
                  clickElement: 'button',
                  clickContent: 'Назад',
                  uniqueId: 'menu-back-button',
                  transitionType: 'inside-link',
                }}
              >
                <ChevronLeftIcon /> Назад
              </Button>{' '}
              <BurgerButton
                onClick={onBurgerClickHandler}
                isBurgerOpen={isBurgerOpen}
              />
            </div>
          )}

          {content.list && content.type === 'list' && (
            <div className={s.listWrapper}>
              <List
                onClickForClose={onClickOnSameLink}
                list={content.list}
                gridColumns={content.gridColumns}
                sectionTitle={content.title}
              />
            </div>
          )}
          {content.type === 'catalog' && (
            <>
              {content.catalogItems?.categories &&
                content.catalogItems?.products && (
                  <Products
                    onClickForClose={onClickOnSameLink}
                    products={{
                      items: content.catalogItems.products,
                      categories: content.catalogItems.categories,
                    }}
                  />
                )}
            </>
          )}
        </div>
      </div>
    </PortalModal>
  ) : (
    <MobileModalCustom
      open={isBurgerOpen}
      onClose={onClickForClose}
      className={s.modal}
      size="full"
    >
      <MobileModalCustom.Body
        content={
          <div>
            {windowWidth && windowWidth <= HEADER_DESKTOP_WIDTH && (
              <div className={s.modalHeader}>
                <ButtonFunction
                  className={s.modalBackButton}
                  appearance="neutral"
                  size="s"
                  onClick={() => {
                    onClickForClose();
                    clickAnalytics({
                      action: 'click',
                      clickZone: 'search',
                      clickElement: 'button',
                      clickContent: 'Назад',
                      uniqueId: 'menu-back-button',
                      transitionType: 'inside-link',
                    });
                  }}
                  icon={<ArrowLeftSVG size={24} />}
                />

                <Typography
                  tag="h2"
                  size="l"
                  purpose="title"
                  family="sans"
                  className={s.modalTitle}
                >
                  {content.title}
                </Typography>
              </div>
            )}
            {content.list && content.type === 'list' && (
              <div className={s.listWrapper}>
                <List
                  onClickForClose={onClickOnSameLink}
                  list={content.list}
                  gridColumns={content.gridColumns}
                  sectionTitle={content.title}
                />
              </div>
            )}
            {content.type === 'catalog' && (
              <>
                {content.catalogItems?.categories &&
                  content.catalogItems?.products && (
                    <Products
                      onClickForClose={onClickOnSameLink}
                      products={{
                        items: content.catalogItems.products,
                        categories: content.catalogItems.categories,
                      }}
                    />
                  )}
              </>
            )}
          </div>
        }
      />
    </MobileModalCustom>
  );
}
