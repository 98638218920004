import { ButtonPromo } from '@sbercloud/uikit-product-button-predefined';
import { Avatar } from '@snack-uikit/avatar';
import {
  ButtonFilled,
  ButtonFunction,
  ButtonSimple,
} from '@snack-uikit/button';
import { Dropdown } from '@snack-uikit/dropdown';
import cn from 'classnames';
import { useAuth } from 'react-oidc-context';
import { consoleRedirectUrl, consoleUrlReg } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useFormatConsoleUrl } from 'src/hooks/useFormatConsoleUrl';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { BurgerButton, HeaderSearchInput } from '../Header/components';
import { DropdownContent } from '../Header/components/DropdownContent';
import s from './WithHeaderCTA.module.scss';

const HEADER_DESKTOP_WIDTH = 1269;

type ButtonsTypes = {
  withoutSearch: boolean | undefined;
  isInputVisible: boolean;
  isBurgerOpen: boolean;
  globalSearchIsVisible: boolean;
  withConsultationButton: boolean;
  onClickOnInputHandler: () => void;
  onConsultationClickHandler: () => void;
  onBurgerClickHandler: () => void;
};

type ButtonsPropsMobile = {
  onConsultationClickHandler: () => void;
};

export const ButtonsWithRegister = (props: ButtonsTypes) => {
  const {
    withoutSearch,
    isInputVisible,
    onClickOnInputHandler,
    globalSearchIsVisible,
    withConsultationButton,
    onConsultationClickHandler,
    onBurgerClickHandler,
    isBurgerOpen,
  } = props;
  const { isAuthenticated, user, isLoading } = useAuth();
  const logInUrl = useFormatConsoleUrl(consoleRedirectUrl, 'header', 'Войти');
  const regInUrl = useFormatConsoleUrl(consoleUrlReg, 'header', 'Регистрация');

  const { customAnalytics, clickAnalytics } = useAnalytics();
  const { windowWidth } = useWindowWidth();

  return (
    <div className={s.buttonsWrapper}>
      {!withoutSearch &&
        !isInputVisible &&
        windowWidth &&
        windowWidth < HEADER_DESKTOP_WIDTH && (
          <HeaderSearchInput
            onClick={onClickOnInputHandler}
            active={globalSearchIsVisible}
          />
        )}

      {windowWidth &&
        windowWidth > HEADER_DESKTOP_WIDTH &&
        withConsultationButton && (
          <ButtonFunction
            size="s"
            label="Связаться с нами"
            onClick={() => {
              onConsultationClickHandler();
              clickAnalytics({
                action: 'click',
                clickZone: 'header',
                clickElement: 'button',
                clickContent: 'Связаться с нами',
                uniqueId: 'header-consultation',
                transitionType: 'inside-link',
              });
            }}
          />
        )}

      {isAuthenticated && !isLoading ? (
        <ButtonFilled
          className={cn(s.consoleButton, {
            [s.mobileHidden]: isInputVisible,
          })}
          label="Личный кабинет"
          appearance="primary"
          size="s"
          href={logInUrl}
          data-abt="sign-in"
          onClick={() => {
            customAnalytics('Btn-head-lk-enter', ['']);
            clickAnalytics({
              action: logInUrl,
              clickZone: 'header',
              clickElement: 'button',
              clickContent: 'Личный кабинет',
              uniqueId: 'header-sign-in',
              transitionType: 'inside-link',
            });
          }}
        />
      ) : (
        <>
          <ButtonFilled
            className={cn(s.consoleButton, {
              [s.mobileHidden]: isInputVisible,
            })}
            label="Войти"
            appearance="primary"
            size="s"
            href={logInUrl}
            data-abt="sign-in"
            onClick={() => {
              customAnalytics('Btn-head-lk-enter', ['']);
              clickAnalytics({
                action: logInUrl,
                clickZone: 'header',
                clickElement: 'button',
                clickContent: 'Войти',
                uniqueId: 'header-sign-in',
                transitionType: 'inside-link',
              });
            }}
          />
          {windowWidth && windowWidth > HEADER_DESKTOP_WIDTH && (
            <ButtonPromo
              className={cn(s.consoleButton, s.consoleButtonReg, {
                [s.mobileHidden]: isInputVisible,
              })}
              label="Зарегистрироваться"
              appearance="secondary"
              size="s"
              href={regInUrl}
              data-abt="sign-up"
              onClick={() => {
                customAnalytics('Btn-head-lk-reg', ['']);
                clickAnalytics({
                  action: regInUrl,
                  clickZone: 'header',
                  clickElement: 'button',
                  clickContent: 'Зарегистрироваться',
                  uniqueId: 'header-sign-up',
                  transitionType: 'inside-link',
                });
              }}
            />
          )}
        </>
      )}

      {isAuthenticated && windowWidth && windowWidth > HEADER_DESKTOP_WIDTH && (
        <Dropdown
          offset={8}
          placement="bottom-end"
          className={s.dropdown}
          content={
            <DropdownContent
              lastName={user?.profile?.given_name || ''}
              userName={user?.profile?.family_name || ''}
            />
          }
        >
          <Avatar
            name={`${user?.profile?.given_name} ${user?.profile?.family_name}`}
            size="s"
            showTwoSymbols
            className={s.authAvatar}
            appearance="green"
          />
        </Dropdown>
      )}

      {windowWidth && windowWidth < HEADER_DESKTOP_WIDTH && !isInputVisible && (
        <BurgerButton
          onClick={onBurgerClickHandler}
          isBurgerOpen={isBurgerOpen}
          size="s"
        />
      )}
    </div>
  );
};

export const ButtonsWithRegisterMobile = (props: ButtonsPropsMobile) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { onConsultationClickHandler } = props;
  const { clickAnalytics, customAnalytics } = useAnalytics();

  const logInUrl = useFormatConsoleUrl(consoleRedirectUrl, 'header', 'Войти');
  const regInUrl = useFormatConsoleUrl(consoleUrlReg, 'header', 'Регистрация');

  return (
    <div className={s.mobileButtonWrapper}>
      <ButtonSimple
        className={s.sendingButton}
        appearance="neutral"
        size="m"
        label="Связаться с нами"
        fullWidth
        onClick={() => {
          onConsultationClickHandler();
          clickAnalytics({
            action: 'click',
            clickZone: 'header',
            clickElement: 'button',
            clickContent: 'Связаться с нами',
            uniqueId: 'modal-mobule-consultation',
            transitionType: 'inside-link',
          });
        }}
      />

      {isAuthenticated && !isLoading ? (
        <ButtonFilled
          className={s.consoleButton}
          label="Личный кабинет"
          appearance="primary"
          size="m"
          href={logInUrl}
          data-abt="sign-in-mobile"
          fullWidth
          onClick={() => {
            customAnalytics('Btn-head-lk-enter', ['']);

            clickAnalytics({
              action: logInUrl,
              clickZone: 'header',
              clickElement: 'button',
              clickContent: 'Личный кабинет',
              uniqueId: 'header-sign-in-mobile',
              transitionType: 'inside-link',
            });
          }}
        />
      ) : (
        <>
          <ButtonFilled
            className={s.consoleButton}
            label="Войти"
            appearance="primary"
            size="m"
            href={logInUrl}
            data-abt="sign-in-mobile"
            fullWidth
            onClick={() => {
              customAnalytics('Btn-head-lk-enter', ['']);

              clickAnalytics({
                action: logInUrl,
                clickZone: 'header',
                clickElement: 'button',
                clickContent: 'Войти',
                uniqueId: 'header-sign-in-mobile',
                transitionType: 'inside-link',
              });
            }}
          />
          <ButtonPromo
            className={s.consoleButtonReg}
            label="Зарегистрироваться"
            appearance="secondary"
            size="m"
            href={regInUrl}
            fullWidth
            data-abt="sign-up-mobile"
            onClick={() => {
              customAnalytics('Btn-head-lk-reg', ['']);

              clickAnalytics({
                action: regInUrl,
                clickZone: 'header',
                clickElement: 'button',
                clickContent: 'Зарегистрироваться',
                uniqueId: 'header-sign-up-mobile',
                transitionType: 'inside-link',
              });
            }}
          />
        </>
      )}
    </div>
  );
};
