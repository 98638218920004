import { TagSpecial, type TagSpecialProps } from '../TagSpecial';

type TagsLength = 2 | 4;

type ProductTagsProps = {
  tagClassName?: string;
  tags: TagSpecialProps[];
  length: TagsLength;
};

const truncateTags = (tags: TagSpecialProps[], length: TagsLength) =>
  tags.slice(0, length);

export function ProductTags({ tagClassName, tags, length }: ProductTagsProps) {
  return (
    <>
      {truncateTags(tags, length).map((tag, i) => (
        <TagSpecial
          className={tagClassName}
          key={`product-tag-${i}`}
          {...(tag as TagSpecialProps)}
        />
      ))}
    </>
  );
}
