import { Layout as UIKitLayout } from '@sbercloud/uikit-product-site-layout';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useState } from 'react';
import { Link } from 'src/components/Link';
import { Logo } from 'src/components/Logo';
import { backendHost } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useHeaderPosition } from 'src/hooks/useHeaderPosition';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Catalog } from 'src/types/backendContent';
import { Form } from 'src/uikit/Form';
import { getUxtFromLc } from 'src/utils/getUxt';
import useSWR from 'swr';

import { ButtonsWithRegister } from '../WithHeaderCTA';
import { HeaderSearchInput, ModalMenu, SubHeader } from './components';
import { HeaderSearch } from './components/HeaderSearch';
import { ModalBurger } from './components/ModalBurger';
import s from './Header.module.scss';
import { MENU_ITEMS } from './mock';
import { ProductsType } from './types';

async function fetcher<T>(key: string): Promise<T> {
  const res = await fetch(backendHost + key);
  if (!res.ok) throw new Error(`fetcher`);
  const responce = await res.json();
  if (!responce.success || !responce.data) throw new Error(`fetcher`);
  return responce.data;
}

export const formatLinks = (links: { [key: string]: string }[][]) => {
  const maxLength = Math.max(...links.map((arr) => arr.length));
  const formatted = [];

  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < links.length; j++) {
      const value = links[j][i] || {};
      formatted.push(value);
    }
  }

  return formatted;
};

const HEADER_DESKTOP_WIDTH = 1269;

function Menu({
  items,
  activeMenuItem,
  setActiveMenuItem,
  setIsModalMenuOpen,
  onClick,
  className = '',
}: {
  items: { id: string; title: string; link?: string; target?: string }[];
  activeMenuItem: string | null;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string | null>>;
  setIsModalMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClick(): void;
  className?: string;
}) {
  const { clickAnalytics } = useAnalytics();

  const onMenuItemClickHandler = (id?: string) => {
    if (id && activeMenuItem !== id) {
      setIsModalMenuOpen(true);
      setActiveMenuItem(id);
    } else {
      setIsModalMenuOpen(false);
      setActiveMenuItem(null);
    }

    onClick();
  };

  return (
    <nav className={cn(s.menuWrapper, className)} data-qa="header_nav">
      <ul
        className={cn(s.menu, {
          [s.smallGap]: Boolean(getUxtFromLc()),
        })}
        data-qa="header_nav_list"
      >
        {items.map(({ title, id, link, target }, index) => {
          if (link) {
            return (
              <Link
                data-qa="header_nav_link"
                className={cn(s.menuItem, 'header-mainMenu', 'link')}
                href={link}
                onClick={() => onMenuItemClickHandler()}
                key={id + index}
                id={`layout-header-link-${title}`}
                target={target}
                analyticsOptions={{
                  action: link,
                  clickZone: 'header',
                  clickElement: 'textclick',
                  clickContent: title,
                  uniqueId: `layout-header-link-${title}`,
                  transitionType: 'inside-link',
                }}
              >
                <Typography tag="span" size="s" family="sans" purpose="title">
                  {title}
                </Typography>
              </Link>
            );
          }
          return (
            <li
              key={index}
              className={cn(s.menuItem, 'header-mainMenu', {
                [s.menuItemActive]: activeMenuItem === id,
              })}
              id={`layout-header-item-${title}`}
              data-click="allclicks"
              onClick={() => {
                onMenuItemClickHandler(id);
                clickAnalytics({
                  action: 'click',
                  clickZone: 'header',
                  clickElement: 'textclick',
                  clickContent: title,
                  uniqueId: `layout-header-item-${title}`,
                  transitionType: 'inside-link',
                });
              }}
              data-qa="header_nav_item"
            >
              <Typography tag="span" size="s" family="sans" purpose="title">
                {title}
              </Typography>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

interface HeaderProps {
  className?: string;
  showLayoutBanner?: boolean;
  withoutSearch?: boolean;
  withoutHeaderShadow?: boolean;
  staticHeader?: boolean;
  withConsultationButton?: boolean;
}

export function Header({
  className = '',
  showLayoutBanner = true,
  withoutSearch,
  withoutHeaderShadow,
  withConsultationButton = true,
  staticHeader = false,
}: HeaderProps) {
  const [globalSearchIsVisible, setGlobalSearchIsVisible] = useState(false);
  const [isModalConsultationOpen, setIsModalConsultationOpen] = useState(false);
  const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
  const [isInputVisible, setIsInputVibisle] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const { windowWidth } = useWindowWidth();
  const {
    showHeader,
    showSubheader,
    setShowSubheader,
    headerPosition,
    headerHeight,
  } = useHeaderPosition(staticHeader, isModalMenuOpen, isBurgerOpen);

  const { data: catalogData } = useSWR(
    `/content/v2/catalog?type=products|solutions&hidden=true`,
    (url: string) => fetcher<Catalog>(url),
  );

  const onConsultationClickHandler = () => {
    setActiveMenuItem(null);
    setIsBurgerOpen(false);
    setIsModalMenuOpen(false);
    setGlobalSearchIsVisible(false);
    setIsModalConsultationOpen((prev) => !prev);
  };

  const onBurgerClickHandler = () => {
    setIsModalMenuOpen(false);
    setActiveMenuItem(null);
    setIsModalConsultationOpen(false);
    setGlobalSearchIsVisible(false);
    setIsBurgerOpen((prev) => !prev);
  };

  const onClickForCloseMenuHandler = () => {
    setIsModalMenuOpen(false);
    setActiveMenuItem(null);
  };

  const onClickOnSameLinkHandler = () => {
    setIsBurgerOpen(false);
    setIsModalMenuOpen(false);
    setActiveMenuItem(null);
  };

  const onClickOnMenuItemHandler = () => {
    setGlobalSearchIsVisible(false);
    setIsModalConsultationOpen(false);
  };

  const onClickOnInputHandler = () => {
    setIsInputVibisle(!isInputVisible);
  };

  const onKeyDownOnInputHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') setIsInputVibisle(false);
  };

  const getContentForMenu = (id: string) => {
    let menuItem = MENU_ITEMS.find((item) => item.id === id);

    if (!menuItem) return MENU_ITEMS[0];

    if (id === '0' && catalogData) {
      menuItem = {
        ...menuItem,
        catalogItems: catalogData as ProductsType,
      };
    }

    return menuItem;
  };

  const getContentForRobots = () => {
    const links: {
      title: string;
      link: string;
    }[] = [];

    MENU_ITEMS.map((menuRoot) => {
      if (menuRoot.type === 'list') {
        menuRoot.list?.forEach((menuInside) => {
          menuInside.items.forEach((innerLink) => {
            links.push({
              title: innerLink.title,
              link: innerLink.link,
            });
          });
        });
      }
    });

    return links.map(({ title, link }, index) => {
      return (
        <a key={`${index}_${link}`} href={link}>
          {title}
        </a>
      );
    });
  };

  const robotsLinks = getContentForRobots();

  return (
    <>
      <UIKitLayout.Header
        className={cn(s.root, className, {
          [s.shadow]:
            (headerPosition > 50 && !withoutHeaderShadow) ||
            globalSearchIsVisible,
          [s.withSubHeader]: showSubheader,
        })}
        style={{
          transform: `translateY(-${!showHeader ? headerHeight : 0}px)`,
        }}
        data-attr="layout-header"
      >
        {showLayoutBanner && (
          <SubHeader
            setShowSubheader={setShowSubheader}
            showSubheader={showSubheader}
          />
        )}
        <div className={s.headerTop}>
          <div className={s.wrapper}>
            <Link
              className={cn(s.logoWrapper, {
                [s.hidden]:
                  windowWidth &&
                  windowWidth < HEADER_DESKTOP_WIDTH &&
                  isInputVisible,
              })}
              href="/"
              data-qa="header_logo_link"
              id="layout-header-logo-link"
              analyticsOptions={{
                action: '/',
                clickZone: 'header',
                clickElement: 'textclick',
                clickContent: 'Logo',
                uniqueId: 'layout-header-logo-link',
                transitionType: 'inside-link',
              }}
            >
              <Logo className={s.logo} />
            </Link>

            {!isInputVisible &&
              windowWidth &&
              windowWidth > HEADER_DESKTOP_WIDTH && (
                <Menu
                  setIsModalMenuOpen={setIsModalMenuOpen}
                  activeMenuItem={activeMenuItem}
                  setActiveMenuItem={setActiveMenuItem}
                  items={MENU_ITEMS}
                  onClick={onClickOnMenuItemHandler}
                  className={s.menuDesktop}
                />
              )}

            {!withoutSearch &&
              !isInputVisible &&
              windowWidth &&
              windowWidth > HEADER_DESKTOP_WIDTH && (
                <HeaderSearchInput
                  onClick={onClickOnInputHandler}
                  active={globalSearchIsVisible}
                />
              )}

            {isInputVisible && (
              <div className={s.searchWrapper}>
                <HeaderSearch
                  onCloseSearch={onClickOnInputHandler}
                  onKeyBoardCloseSearch={onKeyDownOnInputHandler}
                />
              </div>
            )}

            <ButtonsWithRegister
              withoutSearch={withoutSearch}
              isInputVisible={isInputVisible}
              isBurgerOpen={isBurgerOpen}
              onClickOnInputHandler={onClickOnInputHandler}
              globalSearchIsVisible={globalSearchIsVisible}
              withConsultationButton={withConsultationButton}
              onConsultationClickHandler={onConsultationClickHandler}
              onBurgerClickHandler={onBurgerClickHandler}
            />
          </div>

          {isModalConsultationOpen && (
            <Form
              productTitle="header"
              title="Связаться с нашим специалистом"
              inputPath="v2/order"
              additionalFields={{ subsegment: 'consultation' }}
              allFormsSentFormType="consultation"
              consultationFormType="header"
              allFormsSentIsPopup
              tryFormType="consultation"
              modalClose={onConsultationClickHandler}
              analyticsFieldFill={{
                formType: 'popup',
                popupPlace: 'header',
                buttonId: 'header-consultation',
              }}
              formVariant="modal"
              successfulSubmissonFormAnalytics={{
                formname: 'consultation',
              }}
            />
          )}

          <div className={s.hidden}>{robotsLinks}</div>

          {isModalMenuOpen && !!activeMenuItem && (
            <ModalMenu
              onClickOnSameLink={onClickOnSameLinkHandler}
              onClickForClose={onClickForCloseMenuHandler}
              content={getContentForMenu(activeMenuItem)}
              showSubheader={showSubheader}
              isBurgerOpen={isBurgerOpen}
              onBurgerClickHandler={onBurgerClickHandler}
              headerHeight={headerHeight}
            />
          )}

          {isBurgerOpen &&
            windowWidth &&
            windowWidth <= HEADER_DESKTOP_WIDTH && (
              <ModalBurger
                onConsultationClickHandler={onConsultationClickHandler}
                withConsultationButton={withConsultationButton}
                onClickForClose={onBurgerClickHandler}
                menuItems={MENU_ITEMS}
                setIsModalMenuOpen={setIsModalMenuOpen}
                isBurgerOpen={isBurgerOpen}
                setActiveMenuItem={setActiveMenuItem}
                activeMenuItem={activeMenuItem}
              />
            )}
        </div>
      </UIKitLayout.Header>
    </>
  );
}
