import { ChevronRightSVG } from '@sbercloud/uikit-product-icons';
import { MobileModalCustom } from '@sbercloud/uikit-product-mobile-modal';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useAuth } from 'react-oidc-context';
import { Link } from 'src/components/Link';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { ButtonsWithRegisterMobile } from 'src/layout/WithHeaderCTA';

import { ExitButton } from '../DropdownContent/ExitButton';
import { UserInfo } from '../DropdownContent/UserInfo';
import s from './ModalBurger.module.scss';

const UserAuth = ({
  userName,
  lastName,
}: {
  userName: string;
  lastName: string;
}) => {
  return (
    <div className={s.userAuth}>
      <div className={s.userAuthContent}>
        <UserInfo userName={userName} lastName={lastName} />
        <ExitButton uniqueId="modal-mobile-user-log-out" />
      </div>
    </div>
  );
};

export function ModalBurger({
  onClickForClose,
  menuItems,
  activeMenuItem,
  onConsultationClickHandler,
  setIsModalMenuOpen,
  setActiveMenuItem,
  withConsultationButton,
  isBurgerOpen,
}: {
  onClickForClose(): void;
  withConsultationButton?: boolean;
  menuItems: { id: string; title: string; link?: string; target?: string }[];
  activeMenuItem: string | null;
  onConsultationClickHandler(): void;
  setIsModalMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string | null>>;
  isBurgerOpen?: boolean;
}) {
  const { isAuthenticated, user } = useAuth();
  const { clickAnalytics } = useAnalytics();

  const onMenuItemClickHandler = (id?: string) => {
    if (id && activeMenuItem !== id) {
      setIsModalMenuOpen(true);
      setActiveMenuItem(id);
    } else {
      setIsModalMenuOpen(false);
      setActiveMenuItem(null);
    }
  };

  const menu = menuItems.map(({ id, title, link, target }) => {
    if (link) {
      return (
        <Link
          data-qa="header_nav_link"
          onClick={onClickForClose}
          className={cn(s.menu, 'header-mainMenu', 'link')}
          href={link}
          target={target}
          key={id}
          id={`header-modalburger-link-${title}`}
          analyticsOptions={{
            action: link,
            clickZone: 'header',
            clickElement: 'textclick',
            clickContent: title,
            uniqueId: `header-modalburger-link-${title}`,
            transitionType: 'inside-link',
          }}
        >
          {title}
        </Link>
      );
    }

    return (
      <div
        onClick={() => {
          onMenuItemClickHandler(id);
          clickAnalytics({
            action: '/',
            clickZone: 'header',
            clickElement: 'textclick',
            clickContent: title,
            uniqueId: `layout-header-item-${title}`,
            transitionType: 'inside-link',
          });
        }}
        data-click="allclicks"
        className={cn(s.menu, 'header-mainMenu')}
        key={id}
      >
        <Typography
          tag="span"
          size="m"
          family="sans"
          purpose="body"
          className={s.menuText}
        >
          {title}
        </Typography>
        <ChevronRightSVG size={24} className={s.menuIcon} />
      </div>
    );
  });

  return (
    <MobileModalCustom
      open={isBurgerOpen || false}
      onClose={onClickForClose}
      className={s.modal}
    >
      <MobileModalCustom.Header title="Меню" align="center" />
      <MobileModalCustom.Body
        content={
          <>
            {isAuthenticated && (
              <UserAuth
                lastName={user?.profile.given_name || ''}
                userName={user?.profile.family_name || ''}
              />
            )}
            {menu}
          </>
        }
      />
      {withConsultationButton && (
        <MobileModalCustom.Footer
          actions={
            <ButtonsWithRegisterMobile
              onConsultationClickHandler={onConsultationClickHandler}
            />
          }
        />
      )}
    </MobileModalCustom>
  );
}
