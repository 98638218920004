import cn from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'src/components/Link';
import { Wrapper } from 'src/components/Wrapper';
import { backendHost } from 'src/global';
import { InfoBannerData, ResponseInfoBanner } from 'src/types/backendContent';
import { Close } from 'src/uikit/icons/ContentIcons/General/Close';
import useSWR from 'swr';

import s from './SubHeader.module.scss';

async function fetcherBanner(key: string): Promise<InfoBannerData> {
  const res = await fetch(backendHost + key);
  if (!res.ok) throw new Error('fetcherBanner');
  const response: ResponseInfoBanner = await res.json();
  if (!response.success || !response.data) throw new Error('fetcherBanner');

  return response.data;
}

interface BannerWrapperProps {
  link?: string;
  children: React.ReactNode;
}

function BannerWrapper({ link, children }: BannerWrapperProps) {
  if (link)
    return (
      <Link
        href={link}
        target="_blank"
        className={cn(s.bannerWrapper, 'link')}
        data-qa="subheader_banner_link"
        id="header-subheader-banner-link"
        analyticsOptions={{
          action: link,
          clickZone: 'header',
          clickElement: 'navbar',
          clickContent: 'subheader_link',
          uniqueId: 'header-subheader-banner-link',
          transitionType: 'inside-link',
        }}
      >
        {children}
      </Link>
    );

  return <div className={s.bannerWrapper}>{children}</div>;
}

interface SubHeaderProps {
  className?: string;
  showSubheader: boolean;
  setShowSubheader: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SubHeader({
  className = '',
  showSubheader,
  setShowSubheader,
}: SubHeaderProps) {
  useEffect(() => {
    const flag = localStorage.getItem('isBannerOpen');
    if (flag === null) {
      localStorage.setItem('isBannerOpen', JSON.stringify(true));
    }
  }, []);

  const handleCloseSubheader = () => {
    setShowSubheader(false);
    localStorage.setItem('isBannerOpen', JSON.stringify(false));
  };

  const { data: bannerInfo } = useSWR(`/content/v1/info`, fetcherBanner);

  useEffect(() => {
    const flag = JSON.parse(localStorage.getItem('isBannerOpen') || 'true');
    if (flag) {
      setShowSubheader(!!bannerInfo?.title);
    }
  }, [bannerInfo]);

  return (
    <>
      {showSubheader && bannerInfo && (
        <div
          className={cn(s.root, className, s[bannerInfo.color])}
          id="subheader"
          data-attribute="no_search_index"
        >
          <Wrapper className={s.wrapper}>
            <BannerWrapper link={bannerInfo.link}>
              <div
                className={cn(s.bannerText, {
                  [s.link]: bannerInfo.link,
                })}
                dangerouslySetInnerHTML={{ __html: bannerInfo.title }}
                data-qa="subheader_banner"
              />
            </BannerWrapper>
            <Close
              className={cn(s.icon, s.iconClose)}
              onClick={handleCloseSubheader}
            />
          </Wrapper>
        </div>
      )}
    </>
  );
}
