'use client';

import { Layout as UIKitLayout } from '@sbercloud/uikit-product-site-layout';
import { Divider } from '@snack-uikit/divider';
import cn from 'classnames';
import { Wrapper } from 'src/components/Wrapper';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { useLayoutType } from '../../hooks/useLayoutType';
import s from './Footer.module.scss';
import { FooterBody } from './FooterBody';
import { FooterBottom } from './FooterBottom';
import { FooterSubscribe } from './FooterSubscribe';
import { FooterTop } from './FooterTop';
import { BODY_BLOCKS } from './mock';

export const DESKTOP_MEDIA_QUERY = 1052;

type FooterProps = {
  className?: string;
  disableSubscribe?: boolean;
};

export function Footer({ className, disableSubscribe }: FooterProps) {
  const layoutType = useLayoutType();
  const { windowWidth } = useWindowWidth();

  return (
    <UIKitLayout.Footer
      className={cn(s.footer, className)}
      layoutType={layoutType}
    >
      <Wrapper className={s.footerWrapper}>
        <div className={s.blockGrid}>
          <div className={s.footerTopWrapper}>
            <FooterTop />
            {windowWidth && windowWidth > DESKTOP_MEDIA_QUERY && (
              <FooterSubscribe disable={disableSubscribe} />
            )}
          </div>
          <FooterBody blocks={BODY_BLOCKS} />
        </div>

        {windowWidth && windowWidth < DESKTOP_MEDIA_QUERY && (
          <FooterSubscribe disable={disableSubscribe} />
        )}

        <Divider />
        <FooterBottom />
      </Wrapper>
    </UIKitLayout.Footer>
  );
}
