import s from './DropdownContent.module.scss';
import { ExitButton } from './ExitButton';
import { UserInfo } from './UserInfo';

interface DropdownContentProps {
  userName: string;
  lastName: string;
}

const DropdownContent = ({ userName, lastName }: DropdownContentProps) => {
  return (
    <div className={s.wrapper}>
      <div className={s.col}>
        <UserInfo userName={userName} lastName={lastName} />
      </div>
      <div className={s.col}>
        <ExitButton uniqueId="header-log-out" label="Выйти из аккаунта" />
      </div>
    </div>
  );
};

export { DropdownContent };
