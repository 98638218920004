import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

import s from './CookiePop.module.scss';

export function CookiePop() {
  const [userAgreeWithCookie, setUserAgreeWithCookie] = useLocalStorage(
    'userAgreeWithCookie',
    'false',
  );
  const [showCookiePop, setShowCookiePop] = useState(false);

  useEffect(() => {
    if (userAgreeWithCookie === 'false') setShowCookiePop(true);
  }, [userAgreeWithCookie]);

  return (
    <>
      {showCookiePop && (
        <div className={s.root}>
          <p className={s.text}>
            Для повышения удобства работы с сайтом Cloud.ru использует файлы{' '}
            <a
              className={cn(s.link, 'link')}
              href="https://cdn.cloud.ru/backend/docs/security/cookie_processing.pdf"
              target="_blank"
            >
              cookie
            </a>{' '}
            в соответствии с{' '}
            <a
              className={cn(s.link, 'link')}
              href="https://cdn.cloud.ru/backend/docs/security/cookie_politic.pdf"
              target="_blank"
            >
              Политикой
            </a>
            . В них содержатся данные о прошлых посещениях сайта. Если вы не
            хотите, чтобы эти данные обрабатывались, отключите cookie в
            настройках браузера.
          </p>

          <Button
            onClick={() => {
              setShowCookiePop(false);
              setUserAgreeWithCookie('true');
            }}
            variant="primary"
            data-qa="cookie_button"
            id="cookiepop-button"
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: 'Понятно',
              uniqueId: 'cookiepop-button',
              transitionType: 'inside-link',
            }}
          >
            Понятно
          </Button>
        </div>
      )}
    </>
  );
}
